<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col cols="auto">
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 回答状況チェック</h2>
        </v-col>
        <v-col v-if="targetSurveyType == SURV_FACT_FINDING" cols="auto" sm="auto" md="auto" lg="auto">
          <v-radio-group 
            v-model="searchCondition.type"
            dense
            row
            :readonly="false"
            :rules="[]"
            :loading="loading"
            class="ml-5 mt-0 pt-0"
            @change="search"
          >
            <v-radio
              label="会員名簿"
              :value="0"
            ></v-radio>
            <v-radio
              label="府県別集計"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <DatePicker v-if="targetSurveyType == SURV_CITY_DATA"
            label="対象年月"
            v-model="searchCondition.targetDate"
            format="YYYY年MM月"
            selectType="month"
            :clearable="false"
            :hideDetails="true"
            suffix="分"
            :changeEvt="search"
          ></DatePicker>
          <YearPicker v-else
            label="対象年月"
            v-model="searchCondition.targetDate"
            format="YYYY年"
            selectType="month"
            :clearable="false"
            :hideDetails="true"
            suffix="度"
            :changeEvt="search"
          ></YearPicker>
          <!-- <DatePicker label="対象年月" v-model="searchCondition.targetDate" format="YYYY年MM月" selectType="month" :clearable="false" :hideDetails="true" suffix="度"></DatePicker> -->
        </v-col>
<!--
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="200" :disabled="loading" @click="fileDownload('回答状況チェック', '/storage/surveyList/回答状況ﾁｪｯｸ.xlsm')"><v-icon :small="true">mdi-download</v-icon>回答状況チェック</v-btn>
        </v-col>
-->
        <v-spacer></v-spacer>
        <!-- 月次報告データ・実態調査のみ回答データチェックへのボタン表示 -->
        <v-col v-if="(targetSurveyType == SURV_CITY_DATA) || (targetSurveyType == SURV_FACT_FINDING)" cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="goto_survCheck()">回答データチェック</v-btn>
        </v-col>
        <!-- 電力調査・冷媒調査は回答一覧へのボタン表示 -->
        <v-col v-else cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="goto_answerList()">回答一覧</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="comHeaders"
            :items="answerDataList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border"
            height="calc(100vh - 200px)"
          >
            <template v-if="searchCondition.type == 0" v-slot:item="{ item }">
              <tr :key="item.memberNo">
                <td class="center">
                  {{item.memberNo}}
                </td>
                <td class="left">
                  {{item.companyName}}
                </td>
                <td class="left">
                  {{item.officeName}}
                </td>
                <td class="left">
                  {{replacePrefIdToPrefName(item.prefId)}}
                </td>
                <td class="center">
                  {{item.statusTarget != 3 ? '' : '○'}}
                </td>
                <td class="center">
                  {{item.status1yearAgo != 3 ? '' : '○'}}
                </td>
                <td class="center">
                  {{item.status2yearAgo != 3 ? '' : '○'}}
                </td>
              </tr>
            </template>
            <template v-else v-slot:item="{ item }">
              <tr :key="item.memberNo">
                <td class="center">
                  {{item.prefId}}
                </td>
                <td class="left">
                  {{item.prefName}}
                </td>
                <td class="center">
                  {{item.statusTarget}}
                </td>
                <td class="center">
                  {{item.status1yearAgo}}
                </td>
                <td class="center">
                  {{item.status2yearAgo}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>


    </v-container>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import DatePicker from "./common/DatePicker.vue";
import YearPicker from "./common/YearPicker.vue";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";
import dayjs from "dayjs";

import FactFinding from "../model/factFinding";
import CityDataAnswer from "../model/cityDataAnswer";
import Electricity from "../model/electricity";
import Refrigerant from "../model/refrigerant";

export default {
  mixins: [Mixin],

  components: {
    DatePicker,
    YearPicker,
  },

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    memberNo: '',
    attribute: 0,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      associationId: null,
      type: 0,
      targetDate: '',
    },

    // テーブルヘッダー
    headers: [
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "10%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "25%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "25%" },
        { text: '都道府県', align: 'center', sortable: true, value: 'prefId', width: "10%" },
        { text: '2022年12月', align: 'center', sortable: true, value: 'statusTarget', width: "10%" },
        { text: '2022年11月', align: 'center', sortable: true, value: 'status1yearAgo', width: "10%" },
        { text: '2022年10月', align: 'center', sortable: true, value: 'status2yearAgo', width: "10%" },
    ],

    // テーブルヘッダー会員名簿
    headersMember: [
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "10%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "25%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "25%" },
        { text: '都道府県', align: 'center', sortable: true, value: 'prefId', width: "10%" },
        { text: '2022年12月', align: 'center', sortable: true, value: 'statusTarget', width: "10%" },
        { text: '2022年11月', align: 'center', sortable: true, value: 'status1yearAgo', width: "10%" },
        { text: '2022年10月', align: 'center', sortable: true, value: 'status2yearAgo', width: "10%" },
    ],
    // テーブルヘッダー府県別集計
    headersPref: [
        { text: '都道府県ID', align: 'center', sortable: true, value: 'prefID', width: "10%" },
        { text: '都道府県', align: 'center', sortable: true, value: 'prefName', width: "60%" },
        { text: '2022年12月', align: 'center', sortable: true, value: 'statusTarget', width: "10%" },
        { text: '2022年11月', align: 'center', sortable: true, value: 'status1yearAgo', width: "10%" },
        { text: '2022年10月', align: 'center', sortable: true, value: 'status2yearAgo', width: "10%" },
    ],

    // 回答データ一覧
    answerDataList: [],



  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;  // 対象調査種別

    // 属性による検索条件の設定
    if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.associationId = this.memberNo;
    }

    this.init();
  },

  mounted: async function () {

  },

  methods: {
    init() {
      // 実施年月
      if(this.targetSurveyType == this.SURV_CITY_DATA) {
        // 月次報告(現在年月の1日)
        this.searchCondition.targetDate = dayjs().startOf('month').format().substr(0, 7);
      } else {
        // 実態、電力、冷媒調査(現在年の1月1日)
        this.searchCondition.targetDate = dayjs().startOf('year').format().substr(0, 7);
      }
    },

    search() {
      Debug.log("function[search]");
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      this.loadList();
    },
    
    /** 表示更新 */
    async loadList() {
      Debug.log('function[loadList]');
      this.loading = true;

      // データをリセット
      this.answerDataList = [];

      try {
        let res = null;
        switch(this.targetSurveyType) {
          case 1:
            // 月次報告データ
            Debug.log('月次報告データ');
            res = await CityDataAnswer.getSurveyAnsStatus(this.searchCondition);
            break;
            
          case 2:
            // 実態調査
            Debug.log('実態調査');
            res = await FactFinding.getSurveyAnsStatus(this.searchCondition);
            break;

          case 3:
            // 電力調査
            Debug.log('電力調査');
            res = await Electricity.getSurveyAnsStatus(this.searchCondition);
            break;

          case 4:
            // 冷媒調査
            Debug.log('冷媒調査');
            res = await Refrigerant.getSurveyAnsStatus(this.searchCondition);
            break;
        }

        if (res.status == 200 && res.data) {
          Debug.log(res.data);
          // 回答データ
            this.answerDataList = res.data;
            Debug.log(this.answerDataList);
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }

      this.loading = false;
    },

    /** 調査回答データチェックに遷移 */
    goto_survCheck () {
      // 回答データチェックに遷移する
      //this.$router.push('/survey_check');
      this.$router.push({name: 'SurveyCheck', params:{checkViewTargetDate: this.searchCondition.targetDate}});
    },

    /** 調査回答データ一覧に遷移 */
    goto_answerList () {
      // 回答データ一覧に遷移する
      if(this.targetSurveyType == this.SURV_ELECTRICITY) {
        this.$router.push({name: 'ElectricityList', params:{targetYear: this.searchCondition.targetDate}});
      } else if(this.targetSurveyType == this.SURV_REFRIGERANT) {
        this.$router.push({name: 'RefrigerantList', params:{targetYear: this.searchCondition.targetDate}});
      }
    },

    // テーブルヘッダーを変更する
    changeHeader() {
      Debug.log('function[changeHeader]');

      let targetDate = dayjs(this.searchCondition.targetDate+'-01');
      let offset = 0;
      if(this.searchCondition.type == 0) {
        // 会員名簿
        offset = 4;
        // ヘッダーの変更
        this.headers = this.headersMember;
      } else {
        // 府県別集計
        offset = 2;
        // ヘッダーの変更
        this.headers = this.headersPref;
      }
      const count = 3;

      // 年月ヘッダー
      let tmpColumn = '';
      for (let i = 0; i < count; i++) {
        // ヘッダーに設定
        if(this.targetSurveyType == this.SURV_CITY_DATA) {
          tmpColumn = targetDate.subtract(i, 'month').format('YYYY年M月');
        } else {
          tmpColumn = targetDate.subtract(i, 'year').format('YYYY年');
        }
        this.$set(this.headers[offset+i], "text", tmpColumn);
      }
    },
  },

  watch: {

  },

  computed: {
    comHeaders() {
      this.changeHeader();
      return this.headers;
    }
  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

</style>
